























































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import CustomerIssueDialog from '../components/CustomerIssueDialog.vue'
import Alert from '@/components/Alert.vue'
import ICustomerIssue from '../models/ICustomerIssue'
import CustomerFilterPanel from '../components/CustomerFilterPanel.vue'
import ISearchModel from '../models/ISearchModel'
import { RawLocation } from 'vue-router'

const { mapGetters, mapActions } = createNamespacedHelpers('Customers')

export default Vue.extend({
  components: {
    CustomerFilterPanel,
    CustomerIssueDialog,
    Alert
  },
  data() {
    return {
      loadFavorites: false,
      customerIssue: null as ICustomerIssue,
      searchModel: {} as ISearchModel,
      loading: false,
      searchError: null,
      loadingText: 'Searching...'
    }
  },
  computed: {
    ...mapGetters(['hasAccess', 'searchResults', 'error'])
  },
  methods: {
    ...mapActions({
      fileIssue: 'fileIssue',
      search: 'search',
      byTpid: 'byTpid',
      export: 'export',
      clearSearch: 'clearSearch'
    }),
    async onSubmitCustomerIssue() {
      await this.fileIssue(this.customerIssue)
      this.customerIssue = null
    },
    async onSearch(val) {
      this.loadingText = 'Searching...'
      this.searchModel = val
      this.loading = true
      try {
        try {
          await this.search(this.searchModel)
          this.updateUrl()
        } catch (e) {
          this.searchError = e
        }
      } finally {
        this.loading = false
      }
    },
    async onExport() {
      this.loadingText = 'Exporting...'
      this.loading = true
      try {
        await this.export(this.searchModel)
      } finally {
        this.loading = false
      }
    },
    onBack() {
      this.searchModel = null
      this.clearSearch()
      this.updateUrl()
    },
    updateUrl() {
      const route = this.$route
      const newRoute: RawLocation = {
        name: route.name,
        params: route.params,
        query: {},
        hash: route.hash,
        path: route.path
      }

      if (this.searchModel) {
        newRoute.query.searchText = this.searchModel.searchText || undefined
        newRoute.query.customerId = this.searchModel.customerId === 0 ? undefined : this.searchModel.customerId.toString() || undefined
        newRoute.query.favorites = this.searchModel.favorites === false ? undefined : this.searchModel.favorites.toString()
        newRoute.query.directAssignments = this.searchModel.directAssignments === false ? undefined : this.searchModel.directAssignments.toString()
      }
      if (JSON.stringify(route.query) !== JSON.stringify(newRoute.query)) {
        this.$router.push(newRoute)
      }
    },
    async load() {
      if (this.searchResults === null) {
        const newRoute: RawLocation = {
          name: 'CustomerLookup',
          query: this.$route.query
        }
        this.$router.push(newRoute)
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.load()
      }
    }
  },
  mounted() {
    this.load()
  }
})
