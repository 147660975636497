var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"cursor":"default"},attrs:{"items":_vm.value,"item-key":"listingId","headers":_vm.headers,"hide-default-footer":"","dense":"","disable-pagination":"","sort-by":_vm.sortBy,"show-select":"","sort-desc":_vm.sortDesc,"must-sort":"","expanded":_vm.expanded},on:{"item-selected":_vm.onItemSelected,"toggle-select-all":_vm.toggleSelectAll},scopedSlots:_vm._u([{key:"item.listingName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"start","no-gutters":""}},[_c('v-col',[_c('a',{attrs:{"href":item.docsUrl,"target":"_blank"}},[_vm._v(_vm._s(item.listingName))])]),_c('v-col',{staticClass:"shrink"},[(_vm.showEosIcon(item))?_c('v-icon',{attrs:{"color":_vm.getEosIconColor(item),"small":""}},[_vm._v(_vm._s(_vm.getEosIcon(item)))]):_vm._e()],1)],1)]}},{key:"item.supportPolicy",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getSupportPolicyUrl(item.supportPolicy),"target":"_blank"}},[_vm._v(_vm._s(item.supportPolicy))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [(item.startDate)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.startDate)))]):_vm._e()]}},{key:"item.endDate1",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":_vm.getTitle(item)}},[_vm._v(_vm._s(_vm._f("date")(item.endDate1)))])]}},{key:"item.monthsRemaining",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTimeRemaining(item)))])]}},{key:"item.endDate2",fn:function(ref){
var item = ref.item;
return [(item.endDate2)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.endDate2)))]):_vm._e()]}},{key:"item.migrationPath",fn:function(ref){
var item = ref.item;
return [(item.migrationPath)?_c('a',{attrs:{"href":item.migrationPath.url,"target":"_blank"}},[_vm._v(_vm._s(item.migrationPath.label))]):_vm._e()]}},{key:"item.licenses",fn:function(ref){
var item = ref.item;
return [(item.oem)?_c('v-tooltip',{staticClass:"mt-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.getLicensesForProduct(item)))])])]}}],null,true)},[_c('span',[_vm._v("License count unknown - this product sold via OEMs.")])]):_c('span',[_vm._v(_vm._s(_vm.getLicensesForProduct(item)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-5",attrs:{"colspan":headers.length}},[_c('CustomerLicenseTable',{attrs:{"value":_vm.licenses,"product-id":item.listingId}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }