






















import FormMixin from '@/mixins/FormMixin'
import TemplateSelect from '@/areas/templates/components/TemplateSelect.vue'

export default FormMixin.extend({
  components: {
    TemplateSelect
  }
})
