






















































































import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import IProductSearchResultModel from '../models/IProductSearchResultModel'
import ICustomerLicenseModel from '../models/ICustomerLicenseModel'
import CustomerLicenseTable from './CustomerLicenseTable.vue'
import { sum } from 'lodash-es'

export default Vue.extend({
  props: {
    value: Array as () => IProductSearchResultModel[],
    licenses: Array as () => ICustomerLicenseModel[]
  },
  components: {
    CustomerLicenseTable
  },
  data() {
    return {
      selected: [],
      expanded: [],
      sortBy: ['listingName'],
      sortDesc: [true]
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        {
          text: 'Listing Name',
          value: 'listingName'
        },
        {
          text: 'Policy Type',
          value: 'supportPolicy'
        },
        {
          text: 'Start Date',
          value: 'startDate',
          class: 'text-no-wrap'
        },
        {
          text: 'Mainstream / Retirement',
          value: 'endDate1',
          class: 'text-no-wrap'
        },
        {
          text: 'Extended End',
          value: 'endDate2',
          class: 'text-no-wrap'
        },
        {
          text: 'Migration Path',
          value: 'migrationPath',
          class: 'text-no-wrap'
        },
        {
          text: 'Time Remaining',
          value: 'monthsRemaining',
          class: 'text-no-wrap',
          sort: (a, b) => this.sort(a, b)
        },
        {
          text: 'Licenses',
          value: 'licenses',
          class: 'text-no-wrap'
        }
      ]
      return headers
    }
  },
  methods: {
    sort(a: any, b: any) {
      if (a === undefined) {
        return -1
      }
      if (b === undefined) {
        return 1
      }
      return a - b
    },
    getLicensesForProduct(item: IProductSearchResultModel) {
      if (item.oem) {
        return 'OEM'
      }
      const sumVar = sum(this.licenses.filter(x => x.productId === item.listingId).map(x => x.licenseCount))
      if (sumVar === 0) {
        return 'Unknown'
      }
      return sumVar
    },
    getTimeRemaining(item: IProductSearchResultModel) {
      const months = item.monthsRemaining
      if (months || months === 0) {
        if (months <= 0) {
          return 'Out of Support'
        }
        return `${months} months`
      }
      return ''
    },
    getTitle(item: IProductSearchResultModel) {
      if (!item || !item.supportPolicy) {
        return undefined
      }
      if (item.supportPolicy.startsWith('Modern')) {
        return 'Retirement Date'
      }
      return 'Mainstream Date'
    },
    toggleSelectAll(item: any) {
      item.items.forEach((x: any) => this.onItemSelected({
        item: x,
        value: item.value
      }))
    },
    onItemSelected(item: any) {
      if (item.item.$_selected !== item.value) {
        if (item.value) {
          this.expanded.push(item.item)
        } else {
          const index = this.expanded.indexOf(item.item)
          if (index > -1) {
            this.expanded.splice(index, 1)
          }
        }
        item.item.$_selected = item.value
        this.$emit('product-selected', item.item)
      }
    },
    getSupportPolicyUrl(policyName: string) {
      const policy = this.$store.getters['SupportPolicies/getSupportPolicyByName'](policyName)
      return policy.externalReferenceUrl
    },
    getEosIcon(item: IProductSearchResultModel) {
      const months = item.monthsRemaining
      if (months <= 0) {
        return '$stop'
      }
      if (months <= 6) {
        return '$square'
      }
      if (months <= 12) {
        return '$triangle'
      }
      if (months <= 24) {
        return '$circle'
      }
      return ''
    },
    showEosIcon(item: IProductSearchResultModel) {
      const months = item.monthsRemaining
      if (months === null) {
        return false
      }
      return months <= 24
    },
    getEosIconColor(item: IProductSearchResultModel) {
      const months = item.monthsRemaining

      if (months <= 6) {
        return 'red'
      }

      if (months <= 12) {
        return 'orange'
      }

      if (months <= 24) {
        return 'gray'
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        newValue.forEach(x => {
          if (x.$_selected) {
            this.selected.push(x)
          }
        })
      }
    }
  }
})
